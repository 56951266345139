<script>
  import { onDestroy, onMount } from 'svelte'

  import Drawer from '../components/Drawer.svelte'
  import ErrorValues from '../components/ErrorValues.svelte'
  import ProgressBar from '../components/ProgressBar.svelte'
  import Sidebar from '../components/Sidebar.svelte'

  import { auth } from '../helpers/auth'
  import { SurveyPoints } from '../helpers/SurveyPoints.js'
  import { SurveyCADAsset } from '../helpers/SurveyCADAsset.js'
  import { SurveyAnchor } from '../helpers/SurveyAnchor.js'
  import { Annotations } from '../helpers/Annotations.js'

  import { APP_DATA } from '../stores/appData'
  import { assets } from '../stores/assets.js'
  import { ui } from '../stores/ui.js'
  import { scene } from '../stores/scene.js'
  import { sessionId } from '../stores/sessionId.js'

  // const { GLCADPass } = window.zeaCad
  // const { PassType } = window.zeaEngine;

  let canvas
  let zeaEngineVersion = 'version unavailable'
  let isMobileDevice = false
  let progress
  let renderer
  let treeItems
  let unsubFromDb

  const urlParams = new URLSearchParams(window.location.search)
  // const sessionId = urlParams.get('session')
  if (urlParams.has('session')) {
    $sessionId = urlParams.get('session')
    // unsubFromDb = surveyPoints.connectToDB(sessionId)
  } else {
    $sessionId = 'Mockup-2.5'
    // unsub4FromDb = surveyPoints.connectToDB(sessionId)
  }

  const handleWindowKeyDown = (event) => {
    if (event.key === 'f') {
      renderer.frameAll()
    }
  }
  const { GLRenderer, Scene, TreeItem, Color, Vec3, SystemDesc } =
    window.zeaEngine

  class MyRenderer extends GLRenderer {
    handleResize(displayWidth, displayHeight) {
      super.handleResize(Math.max(4, displayWidth), Math.max(4, displayHeight))
    }
    setupWebGL($canvas, webglOptions) {
      super.setupWebGL($canvas, webglOptions)
      this.__glcanvas.style.width = '100%'
      this.__glcanvas.style.height = '100%'
    }
  }
  onMount(async () => {
    const { Session, SessionSync } = window.zeaCollab

    if (zeaEngine.packageJson) {
      zeaEngineVersion = `v${zeaEngine.packageJson.version}`
    }

    isMobileDevice = SystemDesc.isMobileDevice

    $scene = new Scene()
    // $scene.setupGrid(10, 10)

    renderer = new MyRenderer(canvas, {
      webglOptions: {
        antialias: true,
        canvasPosition: 'relative',
      },
    })

    renderer.outlineThickness = 0.5
    renderer.outlineColor = new Color(0.25, 0.25, 0.25, 1)

    // Setup the CADPass
    // const cadPass = new GLCADPass();
    // console.log(cadPass)
    // renderer.addPass(cadPass, PassType.OPAQUE);

    // renderer.setScene(scene);
    // renderer.resumeDrawing();

    renderer.setScene($scene)
    renderer.resumeDrawing()

    $scene
      .getSettings()
      .getParameter('BackgroundColor')
      .setValue(new Color('#58C8F4'))

    /// Camera setup -----------------------------------
    renderer
      .getViewport()
      .getCamera()
      .setPositionAndTarget(new Vec3(7, -4.5, 2), new Vec3(2, 0, 4))

    const appData = {
      scene: $scene,
      renderer: renderer,
    }

    /** {{{ COLLAB */
    const SOCKET_URL = 'https://websocket-staging.zea.live'
    const roomId = $sessionId

    const userData = await auth.getUserData()

    if (!userData) {
      return
    }

    const session = new Session(userData, SOCKET_URL)
    session.joinRoom(roomId)

    const sessionSync = new SessionSync(session, appData, userData, {})
    session.sub(Session.actions.USER_JOINED, (userData) => {
      if (userData.id in sessionSync.userDatas) {
        const avatar = sessionSync.userDatas[userData.id].avatar
        avatar.__treeItem.on('childAdded', (event) => {
          const { childItem } = event
          childItem.addHighlight('AvatarHilight', new Color(1, 1, 0, 0.1), true)
        })
      }
    })

    appData.userData = userData
    appData.session = session
    appData.sessionSync = sessionSync
    /** }}} COLLAB */

    APP_DATA.set(appData)

    // Note: Now we specify data loding via URL params.
    // we can then load local assets.
    // e.g.
    // http://localhost:5001/?zcad=../testData/ZSK_MockUpB_m-1.zcad&surveyPoints=../testData/ZSK_MockUpB_ScanData.txt
    const cadAsset = new SurveyCADAsset()
    if (urlParams.has('zcad')) {
      cadAsset.getParameter('FilePath').setValue(urlParams.get('zcad'))
    } else if (urlParams.has('model')) {
      const defaultModel = `https://storage.googleapis.com/zahner-production-8e2af.appspot.com/${urlParams.get(
        'model'
      )}`
      // 'https://storage.googleapis.com/zahner-development.appspot.com/MockupTower-Steel_V5.3-M_Trans.zcad'

      cadAsset.getParameter('FilePath').setValue(defaultModel)
      // console.log('else')
    } else {
      const defaultModel =
        'https://storage.googleapis.com/zahner-development.appspot.com/MockupTower-Steel_V5.3-M_Trans.zcad'

      cadAsset.getParameter('FilePath').setValue(defaultModel)
      // console.log('else')
    }

    $scene.getRoot().addChild(cadAsset)

    const anchors = new TreeItem('anchors')
    const annotations = new TreeItem('annotations')

    $scene.getRoot().addChild(anchors)
    $scene.getRoot().addChild(annotations)

    cadAsset.on('loaded', () => {
      // Linear Gamma adjustment
      const materials = cadAsset.getMaterialLibrary().getMaterials()
      materials.forEach((material) => {
        // Convert linear space values to gamma space values.
        // The shaders assume gamma space values, to convert to linear at render time.
        const baseColorParam = material.getParameter('BaseColor')
        if (baseColorParam) {
          const baseColor = baseColorParam.getValue().toGamma()
          baseColorParam.setValue(baseColor)
        }
      })
      console.log('color adjustment')

      const anchorPointsItems = []
      const annotationItems = []
      const annotationParentTreeItem = new TreeItem('annotations')

      cadAsset.traverse((item) => {
        if (item.hasParameter('AnnotationName')) {
          console.log('annotation name', item)
          const value = item.getParameter('AnnotationName').getValue()
          const valuePl = item.getParameter('AnnotationPlane').getValue()
          try {
            const addAnnotations = new Annotations(
              item.getName(),
              value,
              JSON.parse(valuePl),
              renderer.getViewport().getCamera(),
              item
            )
            annotations.addChild(addAnnotations)
            // annotationParentTreeItem.addChild(addAnnotations.associatedPointsTreeItem, false)
            // anchorPointsItems.push(addAnnotations.associatedPointsTreeItem)
            annotationItems.push(addAnnotations.associatedPointsTreeItem)
            // anchorPointsItems.push(addAnnotations.annotationParentTreeItem)
            // anchorPointsItems.push(annotationParentTreeItem)
          } catch (e) {
            console.warn('Invalid Origin plane value:', value, e)
          }
        }

        if (item.hasParameter('OriginPlane')) {
          const value = item.getParameter('OriginPlane').getValue()
          try {
            const surveyAnchor = new SurveyAnchor(
              item.getName(),
              JSON.parse(value),
              renderer.getViewport().getCamera(),
              item
            )
            anchors.addChild(surveyAnchor)
            anchorPointsItems.push(surveyAnchor.associatedPointsTreeItem)
          } catch (e) {
            console.warn('Invalid Origin plane value:', value, e)
          }
        }
      })
      // anchorPointsItems.push(annotationItems)

      annotationItems.forEach((sub) =>
        annotationParentTreeItem.addChild(sub, false)
      )

      anchorPointsItems.push(annotationParentTreeItem)
      treeItems = anchorPointsItems
      // treeItems = annotationItems
      renderer.frameAll()

      ///////////////////////////////////////////
      // Load Survey Points.
      const surveyPoints = new SurveyPoints('SurveyPoints')

      if (urlParams.has('surveyPoints')) {
        surveyPoints.load(urlParams.get('surveyPoints'))
      } else {
        unsubFromDb = surveyPoints.connectToDB($sessionId)
      }

      $scene.getRoot().addChild(surveyPoints)
      surveyPoints.bind(anchors)
      // renderer.frameAll()
    })

    //not sure about how to get to function correctly, should get object properties
    renderer.getViewport().on('pointerDown', (event) => {
      const intersectionData = event.intersectionData
      if (intersectionData) {
        const geomItem = intersectionData.geomItem
        console.log(geomItem.getPath())
      }
    })

    document.addEventListener('keydown', handleWindowKeyDown)
  })

  onDestroy(() => {
    unsubFromDb && unsubFromDb()
    document.removeEventListener('keydown', handleWindowKeyDown)
  })
</script>

<main class="Main flex-1 relative">
  <canvas bind:this={canvas} class="absolute h-full w-full" />

  <Drawer>
    <Sidebar {treeItems} />
  </Drawer>

  {#if isMobileDevice}
    <ErrorValues />
  {/if}

  <div
    class="absolute bottom-0.5 left-0.5 opacity-10 text-white hover:opacity-90 text-xs"
  >
    Zea Engine {zeaEngineVersion}
  </div>
</main>

{#if progress < 100}
  <div class="fixed bottom-0 w-full">
    <ProgressBar {progress} />
  </div>
{/if}

<style>canvas{touch-action:none}</style>
